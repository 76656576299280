import React, { useCallback } from 'react'
import { useRouter } from 'next/router'
import useTranslation from '@/hooks/useTranslation'
import { useAuth } from '@/providers/Auth'
import { cn } from '@/lib/utils'
import {
  NavigationListItem,
  NavigationListItemProps,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuList
} from '@/ui/shadcn/navigationMenu'

type IconType = React.ComponentType<React.SVGProps<SVGSVGElement>>

export type NavigationItem = {
  id: string
  title: string
  subtitle: string
  type: string
  href: string
  icon: IconType
  row?: number
}

export type NavigationType = {
  id: string
  title: string
  type: string
  href?: string
  icon?: IconType
  items?: NavigationItem[]
}

const HeaderNavigation: React.FC = () => {
  const { t } = useTranslation('headerNavigation')
  const { isAdmin } = useAuth()

  const { push, pathname } = useRouter()

  const handleClickButton = useCallback(() => {
    const isHome = pathname === '/'
    if (isHome) {
      window.location.reload()
    }
    push('/')
  }, [pathname, push])

  return (
    <NavigationMenu>
      <NavigationMenuList>
        {t?.navigation?.map(
          ({ id, title, type, href, items }: NavigationType, index: number) => {
            if (type === 'link' && href) {
              return (
                <button
                  className={cn(
                    navigationMenuTriggerStyle(),
                    'bg-accent hover:bg-gray-200 cursor-pointer'
                  )}
                  onClick={handleClickButton}
                  key={`header-menu-link-${index}-${id}`}
                >
                  <span>{title}</span>
                </button>
              )
            } else if (type === 'dropdown') {
              return (
                <NavigationMenuItem key={`header-menu-dropdown-${index}-${id}`}>
                  <NavigationMenuTrigger className="bg-accent hover:bg-gray-200">
                    {title}
                  </NavigationMenuTrigger>
                  <NavigationMenuContent>
                    <ul
                      className={cn(
                        'grid gap-2 p-6 md:w-[400px] lg:w-[500px] lg:grid-cols-2',
                        !isAdmin && id === 'SETTINGS' && 'lg:grid-cols-1'
                      )}
                    >
                      {items?.map((item: NavigationListItemProps) => {
                        if (
                          (item.id === 'BILLING' ||
                            item.id === 'USERS' ||
                            item.id === 'MY_ACCOUNT') &&
                          !isAdmin
                        ) {
                          return
                        }
                        return <NavigationListItem {...item} key={item.id} />
                      })}
                    </ul>
                  </NavigationMenuContent>
                </NavigationMenuItem>
              )
            }
          }
        )}
      </NavigationMenuList>
    </NavigationMenu>
  )
}

export default HeaderNavigation
