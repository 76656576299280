import React, { useCallback, useMemo } from 'react'
import { Bars3BottomLeftIcon } from '@heroicons/react/24/outline'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import useLocalStorage from '@/hooks/useLocalStorage'
import usePersistedTheme from '@/hooks/usePersistedTheme'
import useTranslation from '@/hooks/useTranslation'
import { useAuth } from '@/providers/Auth'
import { useThemeWhiteLabel } from '@/providers/ThemeWhiteLabel'
import HeaderNavigation from '../HeaderNavigation'
import { cn } from '@/lib/utils'
import Button from '@/ui/atoms/Button'
import { type CurrentVaultId } from '@/ui/atoms/SelectVault'
import SandboxAlert from '@/ui/molecules/SandboxAlert'
import HeaderOptionGroup from '@/ui/organisms/HeaderOptionGroup'

type Props = {
  setSidebarOpen?: (value: boolean) => void
}

const Header = ({ setSidebarOpen }: Props) => {
  const { t } = useTranslation('header')
  const { theme } = usePersistedTheme()
  const { isSandbox, isLogged } = useAuth()
  const { pathname, query, push } = useRouter()
  const [, setCurrentVaultId] = useLocalStorage<CurrentVaultId>(
    'currentVaultId',
    null
  )
  // const { dataThemeWhiteLabel } = useThemeWhiteLabel()
  //   const logoSrc =
  //   dataThemeWhiteLabel?.logoLightUrl && dataThemeWhiteLabel?.logoDarkUrl
  //     ? theme === 'light'
  //       ? dataThemeWhiteLabel.logoLightUrl
  //       : dataThemeWhiteLabel.logoDarkUrl
  //     : theme === 'dark'
  //       ? '/assets/logos/signater/yellow-black-square-with-white-text.png'
  //       : '/assets/logos/signater/yellow-black-square-with-black-text.png'

  // const logoSquareSrc =
  //   dataThemeWhiteLabel?.squareLogoLight && dataThemeWhiteLabel?.squareLogoDark
  //     ? theme === 'light'
  //       ? dataThemeWhiteLabel.squareLogoLight
  //       : dataThemeWhiteLabel.squareLogoDark
  //     : '/assets/logos/signater/logo-square.png'

  const logoSrc =
    theme === 'dark'
      ? '/assets/logos/signater/yellow-black-square-with-white-text.png'
      : '/assets/logos/signater/yellow-black-square-with-black-text.png'
  const logoSquareSrc = '/assets/logos/signater/logo-square.png'

  const urlCreateEnvelopeButton = useMemo(() => {
    if (pathname === '/vaults/[id]' && query.id) {
      setCurrentVaultId(query.id as string)
      return `/envelope?origin=header`
    }

    if (typeof window !== 'undefined') {
      window.localStorage.removeItem('currentVaultId')
    }
    return '/envelope?origin=header'
  }, [pathname, query.id, setCurrentVaultId])

  const handleClickLogo = useCallback(() => {
    const isHome = pathname === '/'
    if (isHome) {
      window.location.reload()
    }
    push('/')
  }, [pathname, push])

  return (
    <div className="sticky top-0 z-50 bg-white dark:bg-black">
      <div className="flex items-center w-full h-16 border-b border-gray-200 shadow-sm ">
        <div className="flex items-center w-full container-main shrink-0 gap-x-4 sm:gap-x-6">
          {isLogged && (
            <div className="flex gap-x-4 lg:hidden">
              <button
                type="button"
                className="text-gray-700 dark:text-gray-700"
                onClick={() => setSidebarOpen?.(true)}
              >
                <span className="sr-only">{t?.openSidebar}</span>
                <Bars3BottomLeftIcon className="w-6 h-6" aria-hidden="true" />
              </button>
              <div className="flex sm:hidden">
                <button
                  className="pt-2 cursor-pointer"
                  onClick={handleClickLogo}
                >
                  <Image
                    width={32}
                    height={32}
                    src={logoSquareSrc}
                    alt="Signater"
                  />
                </button>
              </div>
              <div className="items-start hidden sm:flex">
                <div className="flex items-center h-16 pb-2 pr-2 cursor-pointer shrink-0">
                  <button
                    className="pt-2 cursor-pointer h-16 w-auto max-w-[160px]"
                    onClick={handleClickLogo}
                  >
                    <Image
                      width={160}
                      height={32}
                      src={logoSrc}
                      alt="Signater"
                    />
                  </button>
                </div>
              </div>
            </div>
          )}
          <div className="flex items-center self-stretch justify-between flex-1 w-full">
            <div className="items-center hidden lg:flex gap-x-2">
              <div
                className={cn(
                  'flex items-center h-16 pb-2 pr-2 cursor-pointer',
                  isLogged && 'hidden lg:flex'
                )}
              >
                <button
                  className="pt-2 cursor-pointer"
                  onClick={handleClickLogo}
                >
                  <Image width={160} height={32} src={logoSrc} alt="Signater" />
                </button>
              </div>
              {isLogged && (
                <div className="items-center hidden lg:flex">
                  <HeaderNavigation />
                  <div className="flex ml-2">
                    <Link href={urlCreateEnvelopeButton}>
                      <Button
                        className="rounded-3xl whitespace-nowrap"
                        type="button"
                      >
                        {t?.buttonCreateEnvelope}
                      </Button>
                    </Link>
                  </div>
                </div>
              )}
            </div>
            <div className="flex items-center flex-1 gap-x-4">
              <HeaderOptionGroup />
            </div>
          </div>
        </div>
      </div>
      {isSandbox && <SandboxAlert>{t?.sandboxMode}</SandboxAlert>}
    </div>
  )
}

export default Header
